import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

// Import Containers
import {
  FullLayoutComponent,
  SimpleLayoutComponent
} from './containers';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'user',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
        //,canActivate: [OrgnGuard,AuthGuard]
      },
      {
        path: 'business',
        loadChildren: () => import('./views/business/business.module').then(m => m.BusinessModule)
        //,canActivate: [OrgnGuard,AuthGuard]
      },
      {
        path: 'device',
        loadChildren: () => import('./views/device/device.module').then(m => m.DeviceModule)
        //,canActivate: [OrgnGuard,AuthGuard]
      },
      {
        path: 'subscription',
        loadChildren: () => import('./views/subscription/subscription.module').then(m => m.SubscriptionModule)
        //,canActivate: [OrgnGuard,AuthGuard]
      },
      {
        path: 'items',
        loadChildren: () => import('./views/items/items.module').then(m => m.ItemsModule)
      },
      {
        path: 'userprofile',
        loadChildren: () => import('./views/userprofile/userprofile.module').then(m => m.UserprofileModule)
      },
      {
        path: 'myAccount',
        loadChildren: () => import('./views/myaccount/myaccount.module').then(m => m.MyaccountModule)
      },
      {
        path: 'bill',
        loadChildren: () => import('./views/bill/bill.module').then(m => m.BillModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./views/report/report.module').then(m => m.BillModule)
      },
      {
        path: 'logo',
        loadChildren: () => import('./views/logosetting/logosetting.module').then(m => m.LogosettingModule)
      },
      {
        path: 'pilot',
        loadChildren: () => import('./views/pilot/pilot.module').then(m => m.PilotModule)
      }
    ]
  },
  {
    path: 'pages',
    component: SimpleLayoutComponent,
    data: {
      title: 'Pages'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule),
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
