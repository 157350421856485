import { UserService } from './../../services/user.service';
import { Component, ElementRef, OnInit ,TemplateRef,ViewChild}  from '@angular/core';
import { Router } from '@angular/router';
import { BusinessService } from 'app/services/business.service';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Chart } from 'chart.js';
import{ToastrService} from 'ngx-toastr'

import { SettingsService } from 'app/settings/settings.service';


interface Business {
  businessName?: String,
  GSTN?: String,
  telephone?:String,
  fax?:String,
  CIN?:String,
  address?: String,
  city?: String,
  pin?: String,
  state?: String,
  country?: String,

}


@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('templateSelectBusiness',{static:true})
  private TemplateSelectBusiness: TemplateRef<any>

  @ViewChild('templateCreateBusiness',{static:true})
  private TemplateCreateBusiness: TemplateRef<any>
  @ViewChild('modalCreateBusiness',{static: false}) public modalCreateBusiness: ModalDirective;
  @ViewChild('modalCreateBusinessFocusInput', {static: false}) modalCreateBusinessFocusInput: ElementRef;
  
  modalRef: BsModalRef;
  public businesses: any[];
  public businessId: any;
  public business1: Business= {};
  name = 'Angular 7 chartjs';
  chart: Chart;
  constructor( private modalService: BsModalService,private router: Router,
    private userService: UserService,
    private businessService: BusinessService,
    private settingService: SettingsService
    ) { }


  ngOnInit(): void {
    //console.log('length ')
    //this.modalCreateBusiness = this.modalService.show(this.TemplateCreateBusiness);
    console.log('dusiness ',this.settingService.getBusinessId())

    if(!this.settingService.getBusinessId()){
      console.log('no business id')
      this.businessService.getBusinesses(1, 10)

      .subscribe(resp => {
        this.businesses = resp.docs;
        console.log('dusiness ',this.businesses)
            if(this.businesses.length > 1) {
              this.modalRef = this.modalService.show(this.TemplateSelectBusiness);
            } else if(this.businesses.length === 1)
            {
              console.log('dusiness id ',resp.docs[0]._id)
              this.settingService.setBusinessId(resp.docs[0]._id)
              this.businesses = resp.docs;
            } else {
              this.modalCreateBusiness.show();
              setTimeout(() => {
                this.modalCreateBusinessFocusInput.nativeElement.focus();
              }, 500);
            }
      });
    }

    // this.chart = new Chart('canvas', {
    //   type: 'bar',

    //   data: {
    //     labels: dringlichkeiten.map(x => x.id),
    //     datasets: [
    //       {
    //         label: 'Monthly Report',
    //         data: dringlichkeiten.map(x => x.value),
    //         backgroundColor:  'rgba(255, 99, 132, 0.2)',
    //         borderColor: 'rgba(255, 99, 132, 1)',
    //         borderWidth: 1
    //       }
    //     ]
    //   }


    // });




}

public OnBusinessSelect(){
  console.log(this.businessId);
  this.settingService.setBusinessId(this.businessId);
  this.modalRef.hide();

}

public createBusiness() {
  let telephone;
  if ((this.business1.telephone !== undefined) && (this.business1.telephone !== null)) {
    telephone = this.business1.telephone.toString();
    delete this.business1.telephone;
    this.business1.telephone = telephone;
  }
  console.log('create business   ' , this.business1)
  this.businessService.createBusiness(this.business1)
  .subscribe(resp => {
    console.log('business created')
    console.log(resp)
    this.settingService.setBusinessId(resp._id)
    this.modalCreateBusiness.hide()
  })
}

public onkeydown = function(e) {
  if(!((e.keyCode > 95 && e.keyCode < 106)
    || (e.keyCode > 47 && e.keyCode < 58) 
    || e.keyCode == 8)) {
      if ((e.keyCode != 190) && (e.keyCode != 110)) {
        return false;
      }
  }
}

}

var dringlichkeiten  = []

