import { Component,OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/services/user.service';
import { SettingsService } from '../../settings/settings.service';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit{

  public isDevelopmentRelease = true;
  public list=[ 'Item1','item2','Item3','Item4']
  constructor(private router:Router,
    private userService : UserService,
    private settings : SettingsService){}
//@
  ngOnInit(){
    let activeUrl = this.settings.getActiveUrl();
    let prodUrl = this.settings.getProductionUrl();
    if (prodUrl == activeUrl) {
      this.isDevelopmentRelease = false;
    }
  }
  public LogOut(event) {
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    localStorage.removeItem('terminaltoken');
    localStorage.removeItem('businessId');
    this.router.navigate(["/pages/login"])
  }
  public ClickUserProfile(event){
    // this.modalUserProfile.show()
    console.log('ClickUserProfile')
    this.router.navigate(['/userprofile/userprofile'])

  }

 }
