import { createAction } from '@ngrx/store';

// export const increment = createAction('[Counter Component] Increment');
// export const decrement = createAction('[Counter Component] Decrement');
// export const reset = createAction('[Counter Component] Reset');



import { Action } from '@ngrx/store';
 
export enum countActionTypes {
  increment = '[Counter Component] Increment',
  decrement = '[Counter Component] Decrement',
  reset='[Counter Component] Reset'
}
 
export class increment implements Action {
  readonly type = countActionTypes.increment;
}
 
export class decrement implements Action {
  readonly type = countActionTypes.decrement;
 
  constructor(public payload: string) {}
}

export class reset implements Action {
  readonly type = countActionTypes.reset;
 
  constructor(public payload: string) {}
}
 
export type CountActions = increment | decrement|reset;