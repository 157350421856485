
import { ToastrModule } from 'ngx-toastr';
import { UserService } from './services/user.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { DialogComponent } from './components/dialog/dialog.component';
import { FormsModule } from '@angular/forms';
//import { HttpClient } from '@angular/common/http';
import { ToasterModule } from 'angular2-toaster';
import { AppComponent } from './app.component';
// Import containers
import {
  FullLayoutComponent,
  SimpleLayoutComponent
} from './containers';

const APP_CONTAINERS = [
  FullLayoutComponent,
  SimpleLayoutComponent
]

// Import components
import {
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV,

} from './components';

const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV
]

// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
]

// Import routing module
import { AppRoutingModule } from './app.routing';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// Import 3rd party components

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule, BsDatepickerModule } from 'ngx-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { SettingsService } from './settings/settings.service';
import { DashboardModule } from './views/dashboard/dashboard.module';
import { OutletService} from './services/outlet.service'
import { BusinessService } from './services/business.service';
import { SubscriptionModule } from './views/subscription/subscription.module';
import { DevicesService } from './services/devices.service';
import { UserprofileModule } from './views/userprofile/userprofile.module';
import { DeviceModule} from './views/device/device.module';
import { LogosettingModule } from './views/logosetting/logosetting.module';
import { ItemsModule } from './views/items/items.module';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store/app.reducer';

import { from } from 'rxjs';
@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    AppRoutingModule,
    DashboardModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    DeviceModule,
    ItemsModule,
    LogosettingModule,
    UserprofileModule,
    BsDatepickerModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers)
   // DialogComponent

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    DialogComponent

  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
  AuthService,AuthGuard,
  SettingsService,
  UserService,
  OutletService,
  DevicesService,
  BusinessService,
  SubscriptionModule
 ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
