import { createReducer, on } from '@ngrx/store';
import  *  as countAction from './counter.actions';
//import  {increment,decrement,reset}   from './counter.actions';
import { Action } from '@ngrx/store';


export const initialState = 0;

// export const counterReducer = createReducer(initialState,
//   on(increment, state => state + 1),
//   on(decrement, state => state - 1),
//   on(reset, state => 0),
// );


export const counterReducer=function reducer(state = initialState, action: countAction.CountActions): number {
  console.log("inside reducer")
  switch (action.type) {
    case (countAction.countActionTypes.increment):
        return state=state+1
        break;
    case (countAction.countActionTypes.decrement):
        return state=state-1
         break;
    case (countAction.countActionTypes.reset):
        return state=0
         break;
 
    default:
      return state;
  }
}

///https://www.intertech.com/Blog/ngrx-tutorial-actions-reducers-and-effects/
