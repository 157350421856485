export const navigation = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'User',
    url: '/user',
    icon: 'icon-user',
    children: [
      {
        name: 'All Users',
        url: '/user/list'
      },
      {
        name: 'Create User',
        url: '/user/create'
      }
    ]
  },

  {
    name: 'Organization',
    url: '/organization',
    icon: 'icon-people',
    children:[
      {
        name: 'List',
        url: '/organization/list'
      },
      {
        name: 'Create Org',
        url: '/organization/create'
      }
    ]
  },
  {
    name: 'User Activities Log',
    url: '/useractivitieslog',
    icon: 'icon-people',
    children:[
      {
        name: 'List',
        url: '/useractivitieslog'
      }
    ]
  },
  {
    name: 'Leads',
    url: '/leads',
    icon: 'icon-book-open',
    children: [
      {
        name: 'Create Lead',
        url: '/leads/create'
      },
      {
        name: 'List Leads',
        url: '/leads/list'
      }
    ]
  },
  {
    name: 'Contact',
    url: '/contact',
    icon: 'icon-notebook',
    children: [
      {
        name: 'Create contact',
        url: '/contact/create'
      },
      {
        name: 'Contacts List',
        url:'/contact/list'
      }

    ]
  },
  {
    name: 'Service Request',
    url: '/editors',
    icon: 'icon-wrench',
    children: [
      {
        name: 'Open Issues',
        url: '/issues/list'
      }
      // {
      //   name: 'Create Issues',
      //   url: '/issues/create'
      // }
      // {
      //   name: 'Closed Issues',
      //   url: '/editors/code-editors',
      //   icon: 'icon-plus'
      // },
      //  {
      //   name: 'Create Issues',
      //   url: '/editors/code-editors',
      //   icon: 'icon-plus'
      // }
    ]
  },
  {
    name: 'Product Registration',
    url: '',
    icon: 'icon-briefcase',
    children: [
      {
        name: 'All Products',
        url: '/productreg/list'
      },
      {
        name: 'Registration',
        url: '/productreg/register'
      }
    ]
  },
  {
    name: 'Videos',
    url: '/videos',
    icon: 'icon-social-youtube',
    children: [
      {
        name: 'All Videos',
        url: '/videos/list'
      },
      {
        name: 'Add Videos',
        url: '/videos/add'
      }
    ]
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
    children: [
      {
        name: 'Leads Status',
        url: '/settings/leadstatusadd'
      },
      {
        name: 'Product Add',
        url: '/settings/productadd'
      },
      {
        name: 'Priority Add',
        url: '/settings/priorityadd'
      },
      {
        name: 'Events Add',
        url: '/settings/eventsadd'
      },
      {
        name: 'Product Family',
        url: '/settings/addfamily'
      },
      {
        name: 'frequently Occurred Issues',
        url: '/settings/frequentlyoccurredissues'
      }
    ]
  },

]

export const navigation_production = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },

  {
    name: 'Product Registration',
    url: '',
    icon: 'icon-briefcase',
    children: [
      {
        name: 'All Products',
        url: '/productreg/list'
      },
      {
        name: 'Registration',
        url: '/productreg/register'
      }
    ]
  },

  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
    children: [
      {
        name: 'Product Add',
        url: '/settings/productadd'
      },
      {
        name: 'Product Family',
        url: '/settings/addfamily'
      },
    ]
  },

]

export const navigation_service = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name: 'Product Registration',
    url: '',
    icon: 'icon-briefcase',
    children: [
      {
        name: 'All Products',
        url: '/productreg/list'
      },
      {
        name: 'Registration',
        url: '/productreg/register'
      }
    ]
  },
  {
    name: 'Service Request',
    url: '/editors',
    icon: 'icon-wrench',
    children: [
      {
        name: 'Open Issues',
        url: '/issues'
      }
    ]
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
    children: [
      {
        name: 'frequently Occurred Issues',
        url: '/settings/frequentlyoccurredissues'
      }
    ]
  },


]

export const navigation_disti = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },

  {
    name: 'Leads',
    url: '/leads',
    icon: 'icon-book-open',
    children: [
      // {
      //   name: 'Create Lead',
      //   url: '/leads/create'
      // },
      {
        name: 'List Leads',
        url: '/leads/list'
      }
    ]
  },
  {
    name: 'Contact',
    url: '/contact',
    icon: 'icon-notebook',
    children: [
      // {
      //   name: 'Create contact',
      //   url: '/contact/create'
      // },
      {
        name: 'Contacts List',
        url:'/contact/list'
      }

    ]
  },


]

export const navigation_default = [
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   icon: 'icon-speedometer',
  //   // badge: {
  //   //   variant: 'info',
  //   //   text: 'NEW'
  //   // }
  // },
  {
    name: 'My Devices',
    url: '/device',
    icon: 'icon-printer',
  },
  {
    name: 'Items',
    url: '/items',
    icon: 'icon-menu',
  },
  // {
  //   name: 'My Account',
  //   url: '/myAccount',
  //   icon: 'icon-book-open',
  // },
  {
    name: 'Reports',
    url: '/report',
    icon: 'icon-chart',
  },
]

export const navigation_support = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Subscription',
    url: '/subscription',
    icon: 'icon-credit-card'
  },
  {
    name: 'Business',
    url: '/business',
    icon: 'icon-organization',
    children: [
      {
        name: 'List Business',
        icon: 'icon-list',
        url: '/business/list'
      }
    ]
  },

  {
    name: 'Device',
    url: '/device',
    icon: 'icon-printer',
    children: [
      // {
      //   name: 'Create Lead',
      //   url: '/leads/create'
      // },
      {
        name: 'Devices',
        icon: 'icon-list',
        url: '/device'
      }
    ]
  },
  {
    name: 'Users',
    url: '/user',
    icon: 'icon-people',
    children: [
      // {
      //   name: 'Create Lead',
      //   url: '/leads/create'
      // },
      {
        name: 'List Users',
        icon: 'icon-list',
        url: '/user'
      }
    ]
  },
  {
    name: 'Pilot',
    url: '/pilot',
    icon: 'icon-list'
  }
]

export const navigation_old = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
    // badge: {
    //   variant: 'info',
    //   text: 'NEW'
    // }
  },
  {
    title: true,
    name: 'Theme'
  },
  {
    name: 'Colors',
    url: '/theme/colors',
    icon: 'icon-drop'
  },
  {
    name: 'Typography',
    url: '/theme/typography',
    icon: 'icon-pencil'
  },
  {
    title: true,
    name: 'Components'
  },
  {
    name: 'Base',
    url: '/base',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Cards',
        url: '/base/cards',
        icon: 'icon-puzzle'
      },
      {
        name: 'Carousels',
        url: '/base/carousels',
        icon: 'icon-puzzle'
      },
      {
        name: 'Collapses',
        url: '/base/collapses',
        icon: 'icon-puzzle'
      },
      {
        name: 'Pagination',
        url: '/base/paginations',
        icon: 'icon-puzzle'
      },
      {
        name: 'Popovers',
        url: '/base/popovers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Progress',
        url: '/base/progress',
        icon: 'icon-puzzle'
      },
      {
        name: 'Switches',
        url: '/base/switches',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tabs',
        url: '/base/tabs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Buttons',
    url: '/buttons',
    icon: 'icon-cursor',
    children: [
      {
        name: 'Buttons',
        url: '/buttons/buttons',
        icon: 'icon-cursor'
      },
      {
        name: 'Dropdowns',
        url: '/buttons/dropdowns',
        icon: 'icon-cursor'
      },
      {
        name: 'Loading Buttons',
        url: '/buttons/loading-buttons',
        icon: 'icon-cursor'
      },
      {
        name: 'Social Buttons',
        url: '/buttons/social-buttons',
        icon: 'icon-cursor'
      }
    ]
  },
  {
    name: 'Charts',
    url: '/charts',
    icon: 'icon-pie-chart'
  },
  {
    name: 'Editors',
    url: '/editors',
    icon: 'fa fa-code',
    children: [
      {
        name: 'Text Editors',
        url: '/editors/text-editors',
        icon: 'icon-note'
      },
      {
        name: 'Code Editors',
        url: '/editors/code-editors',
        icon: 'fa fa-code'
      }
    ]
  },
  {
    name: 'Forms',
    url: '/forms',
    icon: 'icon-note',
    children: [
      {
        name: 'Basic Forms',
        url: '/forms/basic-forms',
        icon: 'icon-note'
      },
      {
        name: 'Advanced Forms',
        url: '/forms/advanced-forms',
        icon: 'icon-note'
      },
    ]
  },
  {
    name: 'Google Maps',
    url: '/google-maps',
    icon: 'icon-map',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'Flags',
        url: '/icons/flags',
        icon: 'icon-star',
        badge: {
          variant: 'success',
          text: 'NEW'
        }
      },
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    children: [
      {
        name: 'Alerts',
        url: '/notifications/alerts',
        icon: 'icon-bell'
      },
      {
        name: 'Modals',
        url: '/notifications/modals',
        icon: 'icon-bell'
      },
      {
        name: 'Toastr',
        url: '/notifications/toastr',
        icon: 'icon-bell'
      }
    ]
  },
  {
    name: 'Plugins',
    url: '/plugins',
    icon: 'icon-energy',
    children: [
      {
        name: 'Calendar',
        url: '/plugins/calendar',
        icon: 'icon-calendar'
      },

      {
        name: 'Draggable Cards',
        url: '/plugins/draggable-cards',
        icon: 'icon-cursor-move'
      },
      {
        name: 'Spinners',
        url: '/plugins/spinners',
        icon: 'fa fa-spinner'
      }
    ]
  },
  {
    name: 'Tables',
    url: '/tables',
    icon: 'icon-list',
    children: [
      {
        name: 'DataTable',
        url: '/tables/datatable',
        icon: 'icon-list'
      },
      {
        name: 'Standard Tables',
        url: '/tables/tables',
        icon: 'icon-list'
      },
    ]
  },
  {
    name: 'Widgets',
    url: '/widgets',
    icon: 'icon-calculator',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Extras',
  },
  {
    name: 'Pages',
    url: '/pages',
    icon: 'icon-star',
    children: [
      {
        name: 'Login',
        url: '/pages/login',
        icon: 'icon-star'
      },
      {
        name: 'Register',
        url: '/pages/register',
        icon: 'icon-star'
      },
      {
        name: 'Error 404',
        url: '/pages/404',
        icon: 'icon-star'
      },
      {
        name: 'Error 500',
        url: '/pages/500',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'UI Kits',
    url: '/uikits',
    icon: 'icon-layers',
    children: [
      {
        name: 'Invoicing',
        url: '/uikits/invoicing',
        icon: 'icon-speech',
        children: [
          {
            name: 'Invoice',
            url: '/uikits/invoicing/invoice',
            icon: 'icon-speech'
          }
        ]
      },
      {
        name: 'Email',
        url: '/uikits/email',
        icon: 'icon-speech',
        children: [
          {
            name: 'Inbox',
            url: '/uikits/email/inbox',
            icon: 'icon-speech'
          },
          {
            name: 'Message',
            url: '/uikits/email/message',
            icon: 'icon-speech'
          },
          {
            name: 'Compose',
            url: '/uikits/email/compose',
            icon: 'icon-speech'
          }
        ]
      }
    ]
  }
];
