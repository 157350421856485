import { Component, OnInit,TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import{ ToastrService} from 'ngx-toastr'
import { SettingsService } from 'app/settings/settings.service';
import {SubscriptionService} from '../../services/subscription.service'
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  @ViewChild('templateCreateSubscription', {static: false})
  private TemplateCreateSubscription : TemplateRef<any>

  modalRefCreateSubscription: BsModalRef;
  sub: any = { OfferDetails: [ {} , {} , {}]};
  OffersList: any[];
  selectedRow: Number;
  Offer: any={OfferDetails:[]};

  constructor(private sservice:SubscriptionService,
    private toasterService: ToastrService,
    private modalService: BsModalService

    ) { }





  ngOnInit() {
    this.sservice.getList()
    .subscribe(resp => {
      this.OffersList = resp;
      console.log("resp ",resp);
      console.log("resp ",this.OffersList)
    })
  }

  public createSubscription(){
    console.log(this.sub);
    let obj = JSON.parse( JSON.stringify(this.sub));
    obj.OfferDetails = obj.OfferDetails.filter(value => Object.keys(value).length !== 0);

    this.sservice.post(obj)
    .subscribe(resp => {
      console.log(resp)
      this.toasterService.success('Create Item successful');
      this.modalRefCreateSubscription.hide();
    })
  }

  public openModal(template: any) {
    this.modalRefCreateSubscription = this.modalService.show(template,Object.assign({}, { class: "modal-lg" }));
  }
offerClick(data){
  console.log('click ',data)

  this.Offer = data;


}

}
