import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BusinessService } from 'app/services/business.service';
import { OutletService } from 'app/services/outlet.service';
import { ItemListService } from 'app/services/itemlist.service';
import { DevicesService } from 'app/services/devices.service';
import { ItemsService } from 'app/services/items.service';
import { ProductService } from 'app/services/product.service';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { ToastContainerDirective,ToastrService} from 'ngx-toastr'
import { SettingsService } from 'app/settings/settings.service';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { FileService } from 'app/services/file.service';
import {Buffer} from 'buffer'
import { error } from '@angular/compiler/src/util';


@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
  // styleUrls: ['../../../../node_modules/spinkit/css/spinkit.css'],

})
export class DeviceComponent implements OnInit {


  @ViewChild('modalDeviceActivation', {static: false}) public modalDeviceActivation: ModalDirective;
  @ViewChild('modalDeviceActivationFocusInput', {static: false}) modalDeviceActivationFocusInput: ElementRef;
  @ViewChild('modalEditDeviceSetting', {static: false}) public modalEditDeviceSetting: ModalDirective;
  @ViewChild('modalEditDeviceHF', {static: false}) public modalEditDeviceHF: ModalDirective;
  @ViewChild('modalCreateItemList', {static: false}) public modalCreateItemList: ModalDirective;
  @ViewChild('modalCreateItem', {static: false}) public modalCreateItem: ModalDirective;
  @ViewChild('modalCreateSubscription', {static: false}) public modalCreateSubscription: ModalDirective;
  @ViewChild('modalCreateOutlet', {static: false}) public modalCreateOutlet: ModalDirective;
  @ViewChild('modalCreateOutletFocusInput', {static: false}) modalCreateOutletFocusInput: ElementRef;
  @ViewChild('modalMapToOutLet', {static: false}) public modalMapToOutLet: ModalDirective;
  @ViewChild('modalMapToItemList', {static: false}) public modalMapToItemList: ModalDirective;
  @ViewChild('modalLogoEdit', {static: false}) public modalLogoEdit: ModalDirective;

  @ViewChild(ToastContainerDirective,{static: false}) toastContainer: ToastContainerDirective;
  
  public errorHandleCase = [
    {
      type : 'required',
      path : [
        {
          path : 'properties/item/items',
          error : [
            {
              moduleName : 'IName',
              errorMessage : 'Item name is mandatory for Item Creation'
            }
          ]
        }, {
          path : 'properties/item/items',
          error : [
            {
              moduleName : 'businessName',
              errorMessage : 'Business Name is mandatory for Business Creation'
            }
          ]
        }, {
          path : 'properties/item/items',
          error : [
            {
              moduleName : 'outletName',
              errorMessage : 'Outlet Name is mandatory for Outlet Creation'
            }
          ]
        }, {
          path : 'properties/item/items',
          error : [
            {
              moduleName : 'serialNo',
              errorMessage : 'Device Serial Number Empty'
            }
          ]
        }, {
          path : 'properties/item/items',
          error : [
            {
              moduleName : 'itemListId',
              errorMessage : 'Item List not selected'
            }
          ]
        }, {
          path : 'properties/item/items',
          error : [
            {
              moduleName : 'password',
              errorMessage : 'Password Not Entered'
            }
          ]
        }, {
          path : 'properties/item/items',
          error : [
            {
              moduleName : 'email',
              errorMessage : 'Email Required'
            }
          ]
        }, {
          path : 'properties/item/items',
          error : [
            {
              moduleName : 'mobile',
              errorMessage : 'mobile number required'
            }
          ]
        }
      ],
    },
    {
      type : 'minItems',
      path : [
        {
          path : 'properties/item/items/properties/Price',
          error : [
            {
              moduleName : 'Price',
              errorMessage : 'Atleast 1 price is mandatory for Item Creation'
            }
          ]
        }
      ],
    }
  ];

  public businessId: string;
  public deviceList: any[];
  public businesses: any[] = [];
  public selectedBusiness: any ={};
  public deviceHF: [{
    Header: [{
      Text: String,
      Width: Boolean,
      Height: Boolean,
      Alignment: String,
      LineNumber: Number
    }],
    Footer: [{
      Text: String,
      Width: Boolean,
      Height: Boolean,
      Alignment: String,
      LineNumber: Number
    }]
  }]

  public Header: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  public Footer: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];

  public HF: any = {}
  public alignmentOpt = {
    Center: 'Center',
    Left: 'Left',
    Right: 'Right'
  }
  public token ;

  public HFs: any = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
  modalRefDeviceReg: BsModalRef;
  public productsList: any;
  public device: any;
  public deviceId: any;
  public isCollapsed = [];
  isDeviceNotEmpty = true;
  public setting: any = {};

  public deviceSettingDefault=[{
    labelCol: 'Bill Reset Status',
    JsonName: 'BillResetStatus',
    value: null
  }, {
    labelCol: 'Bill Without Stock',
    JsonName: 'BillWithoutStock',
    value: null
  }, {
    labelCol: 'Buzzer Status',
    JsonName: 'BuzzerStatus',
    value: null
  }, {
    labelCol: 'Cash Calculator Status',
    JsonName: 'CashCalculatorStatus',
    value: null
  }, {
    labelCol: 'Customer Name Status',
    JsonName: 'CustomerNameStatus',
    value: null
  }, {
    labelCol: 'Discount Per Item Status',
    JsonName: 'ItemDiscountStatus',
    value: null
  }, {
    labelCol: 'Discount Status',
    JsonName: 'DiscountStatus',
    value: null
  }, {
    labelCol: 'HF Status',
    JsonName: 'HFStatus',
    value: null
  }, {
    labelCol: 'HSN',
    JsonName: 'HSN',
    value: null
  }, {
    labelCol: 'KOT Token Print',
    JsonName: 'KOTTokenPrint',
    value: null
  },  {
    labelCol: 'Open Price Status',
    JsonName: 'OpenPriceStatus',
    value: null
  }, {
    labelCol: 'Payment Mode Status',
    JsonName: 'PaymentStatus',
    value: null
  }, {
    labelCol: 'Stock Status',
    JsonName: 'StockStatus',
    value: null
  }, {
    labelCol: 'Registration Status',
    JsonName: 'RegistrationStatus',
    value: null
  }, {
    labelCol: 'Round Off',
    JsonName: 'RoundOff',
    value: null
  }, {
    labelCol: 'Token Logo Status',
    JsonName: 'TokenLogoStatus',
    value: null
  }, {
    labelCol: 'UDN Status',
    JsonName: 'WaiterStatus',
    value: null
  },{
    labelCol: 'Base Price',
    JsonName: 'BasePrice',
    value: null
  }, {
    labelCol: 'Bill Save Status',
    JsonName: 'BillSaveStatus',
    value: null
  }, {
    labelCol: 'Token Status',
    JsonName: 'ChickenToken',
    value: null
  }, {
    labelCol: 'Consolidated GST Status',
    JsonName: 'ConsolidatedVatStatus',
    value: null
  }, {
    labelCol: 'Duplicate BillStatus',
    JsonName: 'DuplicateBillStatus',
    value: null
  }, {
    labelCol: 'Merge Status',
    JsonName: 'MergeStatus',
    value: null
  }, {
    labelCol: 'GST Status',
    JsonName: 'GSTStatus',
    value: null
  }, {
    labelCol: 'Guest Status',
    JsonName: 'GuestStatus',
    value: null
  }, {
    labelCol: 'KOT Status',
    JsonName: 'KOTStatus',
    value: null
  }, {
    labelCol: 'KOT Token Delete',
    JsonName: 'KOTTokenDelete',
    value: null
  }, {
    labelCol: 'Logo Status',
    JsonName: 'LogoStatus',
    value: null
  }, {
    labelCol: 'Reverse GST Status',
    JsonName: 'ReverseTaxStatus',
    value: null
  }, {
    labelCol: 'SMS Setting status',
    JsonName: 'SMSSettingstatus',
    value: null
  }, {
    labelCol: 'Token HF Status',
    JsonName: 'TokenHFStatus',
    value: null
  }, {
    labelCol: 'GST Selection Status',
    JsonName: 'GSTSelectionStatus',
    value: null
  }
]
  public deviceSettingLeft = [];
  public deviceSettingRight = [];
  public terminalToken: string;
  isPost: Boolean = false;
  isSettingsPost : Boolean = false;
  // public itemListObj: any;
  public ItemObj: any = {
    Price: [{}, {}, {}]
  };
  public OutLets: any;
  public SelectedOutlet: any;
  public ItemList: any;
  public SelectedItemList: any;
  this: any;


  // public list = [ 'Item1', 'item2', 'Item3', 'Item4'];

  constructor(
    private modalService: BsModalService,
    private toasterService: ToastrService,
    private itemsService: ItemsService,
    private settingService: SettingsService,
    private deviceService: DevicesService,
    private itemListService: ItemListService,
    private outletService: OutletService,
    private fileService: FileService,
    private productService: ProductService,
    private bService: BusinessService,
    public router: Router
  ) {}
  ngOnInit() {
    this.toasterService.overlayContainer = this.toastContainer;
    this.HF.Header = this.Header;
    this.HF.Footer = this.Footer;
    this.businessId = this.settingService.getBusinessId();
    this.bService.getBusinesses(1,20)
      .subscribe(resp => {
      this.businesses = resp.docs;
      this.selectedBusiness = this.businesses[0];
      console.log('business List', this.selectedBusiness)
    });
    if (this.businessId == null) {
      this.router.navigate(['/dashboard'])
      console.log('error no Business selected' + this.businessId);
    }
    console.log('device selected' + this.businessId);
    this.getDeviceList();
    for (let i = 0; i < 10; i++) {
      this.isCollapsed[i] = true;
    }
    this.deviceSettingDefault.sort(this.dynamicSort("labelCol"));
    this.getOutlets();
    this.getItemList();
  }
  public getOutlets() {
    this.outletService.getOutlet(this.businessId)
    .subscribe(resp => {
      console.log('outlets ', resp)
      this.OutLets = resp.docs;
    }, error => {

    })
  }
  public onOutletChangeClick(device) {
    this.device = device;
    this.deviceId = device._id;
    this.SelectedOutlet = device.outletId;
    this.modalMapToOutLet.show();
    // this.getOutlets();
  }
  public onItemListChangeClick(device) {
    console.log(device)
    this.device = device;
    this.deviceId = device._id;
    this.SelectedItemList = device.itemListId;
    const body = {
      token : localStorage.getItem('token'),
      deviceId : this.deviceId
    }
    this.deviceService.getTerminalToken(body).subscribe( resp => {
      this.token = resp.token;
      console.log('@@@@@@@@ token @@@@@ ',resp.token)
    }, error => {
      console.log(error);
    })
    this.modalMapToItemList.show();
  }

  public mapToItemListSubmit() {
    const body = {
      itemListId: this.SelectedItemList,
      serialNo: this.device.serialNo,
      businessId: this.businessId
    }
    this.deviceService.PatchM2IDSchema(body)
    .subscribe(resp => {
      this.toasterService.success('Device Mapped Successfully');
      this.modalMapToItemList.hide();
      this.getDeviceList();
    })
  }

  public mapToOutletSubmit() {
    const body = {
      outletId: this.SelectedOutlet,
      businessId: this.businessId,
      serialNo: this.device.serialNo
    }
    console.log('device patch body ', body)
    this.deviceService.patchDevice(body)
      .subscribe(resp => {
        this.toasterService.success('Device Mapped Successfully');
        this.modalMapToOutLet.hide();
        this.getDeviceList();
      })
  }
  public getDeviceList() {
    this.deviceService.getDeviceList(this.businessId, undefined)
    .subscribe(resp => {
      this.deviceList = resp.docs;
      this.isDeviceNotEmpty = false;
      console.log('device ', resp.docs)
    }, error => {
      console.log(error);
    })
  }
  public onDeviceRegSubmit(e, template) {
    const body = {
      productModelId: e.product._id,
      productModelName: e.product.modelNo,
      warrantyDuration: e.product.warranty,
      factoryFirmwareVersion: e.factoryFirmwareVersion,
      deviceIdentifier: e.deviceIdentifier
    }
    console.log('device Registration')
    console.log(JSON.stringify(e.product))
    this.deviceService.deviceReg(body)
      .subscribe(resp => {
        console.log(resp)
        this.toasterService.success('Create Item successful');
        this.modalRefDeviceReg.hide();
      });

  }
  public openModal(template) {
    this.modalDeviceActivation.show()
    setTimeout(() => {
      this.modalDeviceActivationFocusInput.nativeElement.focus();
    }, 500);
  }
  public ShowAllDevices() {
    this.deviceService.getDeviceList(this.businessId, undefined)
      .subscribe(resp => {
        this.deviceList = resp.docs;
        this.isDeviceNotEmpty = false;
        console.log('device ', resp.docs)
      })

  }
  public deviceActivation(serialNo) {
    const body = serialNo;
    const terminalBody = {
      deviceId : String(),
      token : localStorage.getItem('token')
    }

    body.businessId = this.businessId
    console.log('serialNo - ', body)
    this.deviceService.create(serialNo)
    .subscribe(resp => {
      console.log('device Activated');
      console.log(resp);
      this.modalDeviceActivation.hide();
      this.getDeviceList();
      this.toasterService.success('Device Setting Created successful');
    }, error => {
      if (error.error.message !== undefined) {
        this.toasterService.error(error.error.message);
      }
    })
  }

  public arrangeSettingsForHtml() {
    this.deviceSettingLeft = [];
    this.deviceSettingRight = [];

    for (let i = 0; i < this.deviceSettingDefault.length; i++) {
      if ((i % 2) === 0) {
        this.deviceSettingLeft[this.deviceSettingLeft.length] = this.deviceSettingDefault[i];
      } else {
        this.deviceSettingRight[this.deviceSettingRight.length] = this.deviceSettingDefault[i];
      }
    }
  }

  public settingClick(id) {
    this.arrangeSettingsForHtml();    
    this.setting = {};
    this.setting.deviceId = id;
    const body = {
      deviceId : id,
      token : localStorage.getItem('token')
    }
    // this.terminalToken = id
    this.deviceService.getTerminalToken(body)
    .subscribe(resp => {
      this.terminalToken = resp.token;
      this.deviceService.getDeviceSettings(id, resp.token)
      .subscribe(resp => {
        if (resp === '{ 1001 message :Setting Already Updated }') {
          this.isSettingsPost = true;
        } else{
          this.isSettingsPost = false;
          this.device = resp[0];
          for (let key of Object.keys(this.device)) {
            for (let i = 0; i < this.deviceSettingLeft.length; i++) {
              if (this.deviceSettingLeft[i].JsonName === key) {
                this.deviceSettingLeft[i].value = this.device[key]
              }
            }
            for (let i = 0; i < this.deviceSettingRight.length; i++) {
              if (this.deviceSettingRight[i].JsonName === key) {
                this.deviceSettingRight[i].value = this.device[key]
              }
            }
          }
          console.log(resp);
        }
      }, error => {
        console.log('get device Settings error: ', error);
      })
    }, error => {
      console.log('get terminal token error: ', error);
    })
    this.modalEditDeviceSetting.show()
  }

  public editClick(id) {
    console.log(id);
  }

  public HFClick(id) {
    for (let i = 0; i < 10; i++) {
      this.isCollapsed[i] = true;
    }

    this.modalEditDeviceHF.show();
    this.collapse(0);
    
    this.deviceId = id;
    console.log('id  ', id);
    const body = {
      deviceId : id,
      token : localStorage.getItem('token')
    }
    this.deviceService.getTerminalToken(body)
      .subscribe(resp => {
        console.log(resp)
        this.terminalToken = resp.token;
        localStorage.setItem('terminaltoken', this.terminalToken);
        this.deviceService.getDeviceHF(id, resp.token)
          .subscribe(resp => {
            console.log(resp)
            if (resp === '{ 1001 message :HF Logo Already updated or not there in DB }') {
              this.isPost = true;
            } else {
              this.isPost = false;
              console.log('getDeviceHF --- ### ---', resp)
              if (resp.Header !== undefined && resp.Header.length > 0) {
                for (let i = 0; i < resp.Header.length; i++) {
                  // console.log(i, ' ', resp.Header[i])
                  if (!this.isEmpty(resp.Header[i])) {
                    this.HF.Header[i] = resp.Header[i];
                  }
                }
              }
              if (resp.Footer !== undefined && resp.Footer.length > 0) {
                for (let j = 0; j < resp.Footer.length; j++) {
                  if (!this.isEmpty(resp.Footer[j])) {
                    // console.log(j, ' ', resp.Header[j])
                    this.HF.Footer[j] = resp.Footer[j];
                  }
                }
              }
              this.HF.Header = this.Header;
              this.HF.Footer = this.Footer;
              console.log('HF $$ ', this.HF)
            }
          }, error => {
            console.log(error)
          })
      })
  }
  public dynamicSort(property) {
    var sortOrder = 1;
    
    if(property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
    }
    
    return function (a,b) {
    if(sortOrder == -1){
    return b[property].localeCompare(a[property]);
    }else{
    return a[property].localeCompare(b[property]);
    } 
    }
  }
  public deviceEditSettingClick() {
    for (let i = 0; i < this.deviceSettingLeft.length; i++) {
      if (this.deviceSettingLeft[i].value !== null) {
        this.setting[this.deviceSettingLeft[i].JsonName] = this.deviceSettingLeft[i].value;
      }
    }
    for(let i=0; i<this.deviceSettingRight.length; i++){
      if (this.deviceSettingRight[i].value !== null) {
        this.setting[this.deviceSettingRight[i].JsonName] = this.deviceSettingRight[i].value;
      }
    }
    const obj = JSON.parse(JSON.stringify(this.setting));
    obj.deviceId = this.setting.deviceId;
    if(this.isSettingsPost) {
      this.deviceService.postDeviceSettings(obj, this.terminalToken)
      .subscribe(resp => {
        this.toasterService.success('Device Setting Successful');
        this.modalEditDeviceSetting.hide();
      })
    } else {
      this.deviceService.patchDeviceSettings(obj, this.terminalToken)
      .subscribe(resp => {
        this.toasterService.success('Device Setting Successful');
        this.modalEditDeviceSetting.hide();
      });
    }
  }

  public HFSubmit() {
    console.log('Hf ', this.HF);
    console.log(Object.keys(this.HF.Header[2]).length)
    console.log(this.isEmpty(this.HF.Header[2]))
    const Header: any = [];
    const Footer: any = [];
    const newArray: any = {
      Header,
      Footer
    };
    console.log('new Array ', this.HF.Header.length);
    for (let i = 0; i < this.HF.Header.length; i++) {
      if (!this.isEmpty(this.HF.Header[i].Text)) {
        delete this.Header[i]._id;
        newArray.Header.push(this.HF.Header[i])
        this.HF.Header[i].LineNumber = (i + 1);
        // newArray.Header.splice(i,0,this.HF.Header[i])
      }
      // else{
      //   newArray.Header.push({})
      // }
    }
    for (let i = 0; i < this.HF.Footer.length; i++) {
      if (!this.isEmpty(this.HF.Footer[i].Text)) {
        delete this.Footer[i]._id;
        newArray.Footer.push(this.HF.Footer[i])
        this.HF.Footer[i].LineNumber = (i + 1);
      }
      // else{
      //   newArray.Footer.push({})
      // }
    }
    console.log('new Array ', newArray);
    newArray.deviceId = this.deviceId;
    // newArray.businessId = this.businessId;
    console.log('isPost ', this.isPost);

    if (this.isPost) {
      this.deviceService.postDeviceHF(newArray, this.terminalToken)
        .subscribe(resp => {
          console.log('post HF Success ', resp);
          this.toasterService.success('Added HF Success successful');
        }, error => {
          console.log('post HF Failure ', error);
        })

    } else {
      this.deviceService.putDeviceHF(newArray, this.terminalToken)
        .subscribe(resp => {
          console.log('patch HF Success ', resp);
          this.modalEditDeviceHF.hide();
          this.toasterService.success('patch HF Success successful');
        }, error => {
          console.log('patch HF Failure ', error);
        })
    }
  }

  public HFCollapseNext(id, keyValue) {
    // console.log(id, keyValue);
    this.collapse(id+1);
  }

  public collapse(id) {
    let collapseStatus = true;
    if (this.isCollapsed[id] === false) {
      collapseStatus = false;
    }
    for (let j = 0; j <= 10; j++) {
      this.isCollapsed[j] = true;
    }
    if (collapseStatus === true) {
      this.isCollapsed[id] = false;
    }
    console.log(this.isCollapsed)
    console.log(id)
  }
  public getCleanObject(jsonObject) {
    const clone = JSON.parse(JSON.stringify(jsonObject))
    for (const prop in clone) {
      if (this.isEmpty(prop)) {
        console.log('deleting')
        delete clone[prop];
      }
    }
    return JSON.stringify(clone);
  }
  public isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  public getItemList() {
    this.itemListService.get(this.businessId,1,15)
      .subscribe(resp => {
        console.log(resp.docs)
        this.ItemList = resp.docs
      }, error => {
        console.log('Not created Successfully')
      })
  }
  public openItemListModal() {
    this.modalCreateItemList.show();
  }
  public onCreateOutletSubmit(outlet) {
    console.log('outlet ', outlet);
    outlet.businessId = this.settingService.getBusinessId();
    console.log('outlet ', outlet);
    this.outletService.createOutlet(outlet)
      .subscribe(resp => {
        console.log(resp)
        this.modalCreateOutlet.hide();
      }, error => {})
  }
  public getSubscriptionList() {

  }
  public openSubscriptionModal() {
    this.modalCreateSubscription.show();
  }
  public openOutletCreateModal() {
    this.modalCreateOutlet.show();
    setTimeout(() => {
      this.modalCreateOutletFocusInput.nativeElement.focus();
    }, 500);
  }
  public outletListClick() {
    this.outletService.getOutlet(this.businessId)
      .subscribe(resp => {
        console.log(resp)
      }, error => {
        console.log(error)
      })
  }

  public logoClick(id){
    this.deviceId = id;
    this.modalLogoEdit.show();
    
    this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
    this.cxOut.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
    this.getFileDataAndLoadOnConvas("logo.bin");
  }
  public img;
  public logofile;
  public thresholdval=200;
  public scale=1;
  public filesize=0;
  public cx: CanvasRenderingContext2D;
  public cxOut: CanvasRenderingContext2D;
  public imagePath;
  public fileOriginalData;
  @ViewChild("canvas",{static:true}) public canvas: ElementRef;
  @ViewChild("canvasOut",{static:true}) public canvasOut: ElementRef;
  onFileChanged(event) {
    var reader = new FileReader();
    if (event.target.files.length > 0) {
      reader.readAsDataURL(event.target.files[0]);
      const file = event.target.files[0];
      this.fileOriginalData = event.target.files[0];
      this.img = new Image();
      reader.onload = (event) => {
        this.logofile=reader.result;
        this.LoadFile();
      }
    }
  }
  public width = 0;
  public height = 0;
  
  LoadFile()
  {
    var file = this.logofile;
    var img = new Image();
    var thres = this.thresholdval;
    let ctx = this.cx;
    let ctxOut = this.cxOut;
    let gs = this.Grayscale;
    let canvas = this.canvas;
    let scale = this.scale;
    this.imagePath = file;
    var that = this;
    img.src = file as string;
      
    img.onload =  function () {
      ctx.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
      ctx.save();
      ctx.scale(scale,scale);
      ctx.drawImage(img, 0, 0);
      ctx.restore();
      let imgData = ctx.getImageData(0, 0, img.width * scale, img.height * scale)
      let imgdsata1 = gs(imgData,thres);
      console.log('Grayscale ', imgdsata1);
      ctxOut.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
      ctxOut.putImageData(imgdsata1, 0, 0);
      that.width = img.width * scale;
      that.height = img.height * scale;
      that.filesize = that.calcFileSize();
    }
  }

  public Grayscale(pixels, threshold) {
    var d = pixels.data;
    for (var i = 0; i < d.length; i += 4) {
      var r = d[i];
      var g = d[i+1];
      var b = d[i+2];
      var v = (0.2126*r + 0.7152*g + 0.0722*b >= threshold) ? 255 : 0;
      d[i] = d[i+1] = d[i+2] = v
    }
    return pixels;
  };

  public convertTo1BPP(array) {
    var convertedArray = [];
    var widthInBytes = ((array.width / 8) | 0x00);
    var j = 0, i = 0, bitNo = 0, bitNoInc = 0;

    for (i = 0; i < array.height; i++) {
      for (j = 0; j < widthInBytes; j++) {
        convertedArray[((i * widthInBytes) + j + i)] = 0x00;
        for (bitNo = 7, bitNoInc = 0; bitNo >= 0; bitNo--, bitNoInc++) {
          convertedArray[((i * widthInBytes) + j + i)] |= (((array.data[(i * array.width * 4) + (j * 8 * 4) + (bitNoInc * 4)] == 255) ? 0x01 : 0x00) << bitNo);
        }
      }
      if ((array.width % 8) != 0) {
        convertedArray[((i * widthInBytes) + j + i)] = 0xFF;
        for (bitNo = 7, bitNoInc = 0; bitNo >= (7 - ((array.width % 8) - 1)); bitNo--, bitNoInc++) {
          convertedArray[((i * widthInBytes) + j + i)] &= ~(((array.data[(i * array.width * 4) + (j * 8 * 4) + (bitNoInc * 4)] == 255) ? 0x00 : 0x01) << bitNo);
        }
      }
    }
    return convertedArray;
  }

  public calcFileSize(){
    if (this.scale <= 0) {
      return 0;
    }
    let value = this.cxOut.getImageData(0, 0, this.width, this.height);
    let image1BPPData = this.convertTo1BPP(value);
    return ((image1BPPData.length + 100) / 1024);
  }

  public ngAfterViewInit() {
    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
    this.cx = canvasEl.getContext('2d');
    const canvasElOut: HTMLCanvasElement = this.canvasOut.nativeElement;
    this.cxOut = canvasElOut.getContext('2d');
  }

  public setThreshold(event)
  {
    this.thresholdval = event.target.value;
    this.LoadFile();
    this.filesize = this.calcFileSize();
  }

  public setScale(event)
  {
    this.scale = event.target.value;
    this.scale = this.scale / 100;
    this.LoadFile();
    this.filesize = this.calcFileSize();
  }

  public fileUploadToServer(fileData, checksum) {
    let body = {
      "description" : "fileuploading from webpage",
      "deviceId" : this.deviceId,
      "checksum" : checksum,
      "file": {
        "name" : "logo.bin",
        "mimetype" : "application/octet-stream",
        "encoding" : "7bit",
        "truncated" : false,
        "size" : fileData.length,
        "data" : [...fileData]
      }
    }
    let loginbody = {
      deviceId : this.deviceId,
      token : localStorage.getItem('token')
    }
    
    this.deviceService.getTerminalToken(loginbody)
    .subscribe(resp => {
      console.log('terminal token', resp);
      this.terminalToken = resp.token;
      this.fileService.uploadFile(body, this.terminalToken)
        .subscribe(resp => {
          this.modalLogoEdit.hide();
          this.toasterService.success('UploadFile Successful');
        }, err => {
          this.toasterService.error('Logo uploading failed');
          console.log("error------------------>", err)
        });
    });
  }

  public getLogoChecksum(imageData) {
    let checksum = 0;
    for (var i = 0; i < imageData.length; i++) {
      checksum += imageData[i];
    }
    return checksum;
  }

  public logoUploadClick(event) {
    if (this.scale <= 0) {
      this.toasterService.error('logo size : 0, not allowed');
      return;
    }
    let imgData = this.cxOut.getImageData(0, 0, this.width, this.height);
    let image1BPPData = this.convertTo1BPP(imgData);
    if (image1BPPData.length >= (4096 - 100)) {
      this.toasterService.error('logo size > 4Kb');
      return;
    }

    let finalLogoData = [];
    finalLogoData[0] = 0x1B;
    finalLogoData[1] = 0x2A;
    finalLogoData[2] = (((this.width % 8) === 0) ? (((this.width / 8) | 0x00) * 8) : ((((this.width / 8) | 0x00) + 0x01) * 8)) & 0xFF;
    finalLogoData[3] = this.height & 0xFF;
    finalLogoData[4] = 0x00;
    finalLogoData[5] = 0x00;
    var initialLength = finalLogoData.length;

    for (var i = 0; i < image1BPPData.length; i++) {
      finalLogoData[initialLength + i] = image1BPPData[i];
    }
    var byteArray = new Uint8Array(finalLogoData);
    var buffer = Buffer.from(byteArray);
    let checksum = this.getLogoChecksum(buffer);
    // const blob = new Blob([byteArray], { type: 'application/pdf' });
    // FileSaver.saveAs(blob, 'logo.bin');
    this.fileUploadToServer(buffer, checksum);
  }

  public getFileDataAndLoadOnConvas(fileName) {
    let body = {
      "fileName" : fileName
    }
    let loginbody = {
      deviceId : this.deviceId,
      token : localStorage.getItem('token')
    }
    this.deviceService.getTerminalToken(loginbody)
    .subscribe(resp => {
      this.terminalToken = resp.token;
      this.fileService.getFile(body, this.terminalToken)
        .subscribe(resp => {
          this.convertFrom1BPPToGrayScaleAndLoadConvas(Buffer.from(new Uint8Array(resp)));
          this.toasterService.success('Download File Successful');
        }, err => {
          this.toasterService.error('Logo downloading failed');
          console.log("error------------------>", err)
        });
    });
  }

  public convertFrom1BPPToGrayScaleAndLoadConvas(image1BPPData) {
    let greyScaleImg = [];
    let tempBufferData = [];
    let width = (image1BPPData[3] / 8);//converting to bits/pixel
    let height = image1BPPData[4];//already in bits/pixel
    var j = 0, i = 0, bitNo = 0, bitNoInc = 0, bitValue = 0;

    for (i = 7, j = 0; i < (image1BPPData.length - 1); i++, j++)
    {
      tempBufferData[j] = image1BPPData[i];
    }

    for (i = 0; i < height; i++) {
      for (j = 0; j < width; j++) {
        for (bitNo = 7, bitNoInc = 0; bitNo >= 0; bitNo--, bitNoInc++) {
          bitValue = 0;
          bitValue = tempBufferData[((i * width) + j)] & (0x01 << bitNo);
          if (0 !== bitValue) {
            greyScaleImg[(i * (width * 8) * 4) + (j * 8 * 4) + (bitNoInc * 4)] = 255;
            greyScaleImg[(i * (width * 8) * 4) + (j * 8 * 4) + (bitNoInc * 4) + 1] = 255;
            greyScaleImg[(i * (width * 8) * 4) + (j * 8 * 4) + (bitNoInc * 4) + 2] = 255;
          } else {
            greyScaleImg[(i * (width * 8) * 4) + (j * 8 * 4) + (bitNoInc * 4)] = 0;
            greyScaleImg[(i * (width * 8) * 4) + (j * 8 * 4) + (bitNoInc * 4) + 1] = 0;
            greyScaleImg[(i * (width * 8) * 4) + (j * 8 * 4) + (bitNoInc * 4) + 2] = 0;
          }
          greyScaleImg[(i * (width * 8) * 4) + (j * 8 * 4) + (bitNoInc * 4) + 3] = 255;
        }
      }
    }
    let img = new ImageData(new Uint8ClampedArray(greyScaleImg), (width * 8), height);
    this.cx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
    this.cxOut.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height)
    this.cxOut.putImageData(img, 0, 0);
  }
}
