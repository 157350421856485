import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';
import {mergeMap,catchError} from 'rxjs/operators'

@Injectable()
export class ItemListService {

  constructor(private http: HttpClient, private setting: SettingsService) {
    console.log('itemlist service created ')

  }

 public get(id,page,limit): Observable<any> {
   let headers = new HttpHeaders();
   let params = new HttpParams();
   const token = localStorage.getItem('token')
   if (token) {
    headers = headers.append('x-access-token', token)
   }
   const url = this.setting.getItemListUrl()

   params = params.append('page', page)
   params = params.append('limit', limit)
   params = params.append('businessId', id)

   return this.http.get(url, {headers: headers, params: params})
 }

 public create(itemList): Observable<any> {
   let headers = new HttpHeaders();
   let params = new HttpParams();
   const token = localStorage.getItem('token')
   if (token) {
        headers = headers.append('x-access-token', token)
   }
   const body = itemList;
   const url = this.setting.getItemListUrl()
   return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
    if(val['error'] !== undefined)
      return this.http.put(url, body, {headers: headers}).pipe(catchError((err:Response) => {
        return  throwError(err)
      }))
    else
      return of(val)
  }), catchError((err:Response) => {
    return  throwError(err)
  }));
 }
 
  public patch(itemList): Observable<any> {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    const token = localStorage.getItem('token')
    if (token) {
          headers = headers.append('x-access-token', token)
    }
    const body = itemList;
    const url = this.setting.getItemListUrl()
    return this.http.patch(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }
  public delete(itemList): Observable<any> {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    const token = localStorage.getItem('token')
    if (token) {
      headers = headers.append('x-access-token', token)
    }
    params = params.append('itemListId', "");
    const url = this.setting.getItemListUrl();
    return this.http.delete(url, {headers: headers, params: params}).pipe(mergeMap(val => {
      return of(val)
    }), catchError((err:Response) => {
      return throwError(err);
    }));
  }
}
