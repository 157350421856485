import {
  Injectable
} from '@angular/core';
declare var $: any;
// static getA: any;


    
// Release:BuldProd

@Injectable()
export class SettingsService {

  public user: any;
  public app: any;
  public layout: any;
  public url: any;
  public BuildProd: any;
  public BuildDev: any;

  constructor() {

    this.BuildProd = {
      Release: "production",
      apiurl: "https://nbpcs.ngxconnect.co.in/api/v1"
    };
    
    this.BuildDev = {
      Release: "development",
      apiurl: "https://devnbpcs.ngxconnect.co.in/api/v1"
    };

    // User Settings
    // -----------------------------------

    // App Settings
    // -----------------------------------
    this.app = {
      name: 'NGX',
      description: 'NGX Dashboard',
      year: ((new Date()).getFullYear())
    };
    this.url = {
      url: this.BuildProd.apiurl,
      user: '/user',
      item: '/item',
      issues: '/servicerequest',
      videos: '/videos',
      issueslog: '/servicerequestlog',
      product: '/product',
      contact: '/contact',
      dbToken: '/dbtoken',
      lead: '/lead',
      leadlog: '/leadlog',
      priority: '/priority',
      events: '/event',
      activity: '/activity',
      status: '/status',
      organization: '/companydetail',
      productfamily: '/ProductFamily',
      userContactList: '/userContactList',
      productregistration: '/productregistration',
      productmovementlog: '/productmovementlog',
      frequentlyoccurredissues: '/frequentlyoccurredissues',
      business: '/business',
      subscription: '/subscription',
      subscriptionTransaction: 'subscriptionTransaction',
      deviceSetting: '/setting',
      deviceHF: '/conf',
      device : '/device',
      outlet: '/outlet',
      file: '/fileUpload',
      itemList: '/itemlist',
      items: '/item',
      itemCodes: '/item/getItemCodes',
      bill: '/bill',
      daywise: '/daywise',
      itemwise: '/itemwise',
      billwise: '/billwise',
      summary: '/summary',
      settings: '/setting'
    }

    // Layout Settings
    // -----------------------------------


  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }
  getUserSetting(name) {
    return name ? this.user[name] : this.user;
  }
  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }

  setAppSetting(name, value) {
    if (typeof this.app[name] !== 'undefined') {
      this.app[name] = value;
    }
  }
  setUserSetting(name, value) {
    if (typeof this.user[name] !== 'undefined') {
      this.user[name] = value;
    }
  }
  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== 'undefined') {
      return this.layout[name] = value;
    }
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }

  public getActiveUrl() {
    return this.url.url;
  }

  public getProductionUrl() {
    return this.BuildProd.apiurl;
  }

  public getDevUrl() {
    return this.BuildDev.apiurl;
  }

  public getBackEndUrlUser() {
    //console.log(this.url.url+this.url.user)
    return this.url.url + this.url.user;
  }

  public getBackEndUrlDBToken() {
    return this.url.url + this.url.dbToken;
  }

  public getBackEndUrlItem() {
    return this.url.url + this.url.item;
  }

  public getBackEndUrlIssues() {
    return this.url.url + this.url.issues;
  }

  public getBackEndUrlVideos() {
    return this.url.url + this.url.videos;
  }

  public getBackEndUrlIssuesLog() {
    return this.url.url + this.url.issueslog;
  }

  public getBackEndUrlContact() {
    return this.url.url + this.url.contact;
  }

  public getBackEndUrlLead() {
    return this.url.url + this.url.lead;
  }

  public getBackEndUrlLeadLog() {
    return this.url.url + this.url.leadlog;
  }

  public getBackEndUrlLeadType() {
    return this.url.url + this.url.lead;
  }
  public getBackEndUrlProductRegistration() {
    return this.url.url + this.url.productregistration;
  }

  public getBackEndUrlProductMovementLog() {
    return this.url.url + this.url.productmovementlog;
  }
  public getBackEndUrlProduct() {
    return this.url.url + this.url.product;
  }

  public getBackEndUrlEvent() {
    return this.url.url + this.url.events;
  }

  public getBackEndUrlPriority() {
    return this.url.url + this.url.priority;
  }

  public getBackEndUrlLeadStatus() {
    return this.url.url + this.url.status;
  }

  public getBackEndUrlUserActivitiesLog() {
    return this.url.url + this.url.activity;
  }
  public getBackEndUrlProductFamily() {
    return this.url.url + this.url.product + '/ProductFamily';
  }
  public getBackEndUrlUserContactList() {
    return this.url.url + this.url.userContactList;
  }

  public getBackEndUrlFrequentlyOccurredIssues() {
    return this.url.url + this.url.issues + '/frequentlyoccurredissues';
  }

  public getBackEndUrlOrganization() {
    return this.url.url + this.url.organization;
  }

  public getUrlbusiness() {
    return this.url.url + this.url.business;
  }
  public getUrlsubscription() {
    return this.url.url + this.url.subscription;
  }
  public getUrlSubscriptionTransaction() {
    return this.url.url + this.url.subscriptionTransaction;
  }

  public setBusinessId(Id){
    const token=localStorage.setItem('businessId',Id)
  }

  public getBusinessId(){
    return localStorage.getItem('businessId');
  }
  public getUrlDeviceSettings(){
    return this.url.url + this.url.deviceSetting;
  }

  public getUrlDevice(){
    return this.url.url + this.url.device;
  }
  public getUrlBill(){
    return this.url.url + this.url.bill;
  }
  public getUrlBillwiseReport(){
    return this.url.url + this.url.bill + this.url.billwise;
  }
  public getUrlDaywiseReport(){
    return this.url.url + this.url.bill + this.url.daywise;
  }
  public getUrlItemwiseReport(){
    return this.url.url + this.url.bill + this.url.itemwise;
  }
  public getUrlSummaryReport(){
    return this.url.url + this.url.bill + this.url.summary;
  }
  public getUrlDeviceHF(){
    return this.url.url + this.url.deviceHF;
  }

  public getItemUrl() {
    return this.url.url + this.url.items ;
  }

  public getDeleteAllItemUrl() {
    return this.url.url + this.url.items + '/deleteall';
  }

  public getItemCodeUrl() {
    return this.url.url + this.url.itemCodes ;
  }

  public getItemListUrl() {
    return this.url.url + this.url.itemList ;
  }

  public getOutletUrl() {
    return this.url.url + this.url.outlet ;
  }

  public getFileUrl() {
    return this.url.url + this.url.file ;
  }

  public getSettingsUrl() {
    return this.url.url + this.url.settings ;
  }

}
