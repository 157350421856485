import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalModule , ModalDirective } from 'ngx-bootstrap/modal';
import { ToastContainerDirective, ToastrService} from 'ngx-toastr'
import { SettingsService } from 'app/settings/settings.service';
import { Router } from '@angular/router';
import { ItemsService } from 'app/services/items.service';
import { ItemListService } from 'app/services/itemlist.service';
import { DevicesService } from 'app/services/devices.service';
import { from } from 'rxjs';
 import  * as Ajv from 'ajv'
import {VBItem,ItemDSchema} from '../../models/validate/items'
import { throttleTime } from 'rxjs/operators';
import { forkJoin } from 'rxjs/';
import { Utils } from 'ngx-bootstrap';

interface IPrice{
  Price:number;
  IGST:number;
  CGST:number;
  SGST:number;
}
interface IItem {
  IName:string,
  HSN:string,
  ICode:number,
  Unit:string,
  Price:IPrice[],
  DCode:number,
  DName:string,
  GName:string,
  GCode:number,
  CP:number,
  CESS1:number,
  CESS2:number,
  DiscPercent:number,
  DecimalDisableFlag:Boolean,
  OpenPriceFlag:Boolean,
}
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  @ViewChild('modalCreateItem', {static: false}) public modalCreateItem: ModalDirective;
  @ViewChild('modalCreateItemFocusInput', {static: false}) modalCreateItemFocusInput: ElementRef;
  @ViewChild('modalCreateItemList', {static: false}) public modalCreateItemList: ModalDirective;
  @ViewChild('modalItemList',{static: false}) public modalItemList: ModalDirective;
  @ViewChild('modalItemListFocusInput', {static: false}) modalItemListFocusInput: ElementRef;
  @ViewChild('modalDeleteItem',{static: false}) public modalDeleteItem: ModalDirective;
  @ViewChild('modalDeleteItemFocusInput', {static: false}) modalDeleteItemFocusInput: ElementRef;
  @ViewChild('modalDeleteAllItems',{static: false}) public modalDeleteAllItems: ModalDirective;
  @ViewChild('modalDeleteAllItemsFocusInput', {static: false}) modalDeleteAllItemsFocusInput: ElementRef;
  @ViewChild('modalDeleteItemList',{static: false}) public modalDeleteItemList: ModalDirective;
  @ViewChild('fileInput', {static: false})
  
  public fileInputVariable: ElementRef;

  terminalToken: string = '';//'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1Y2M5OTI2MzE3Y2JmOTY0YTM0MGFjMzUiLCJ1c2VydHlwZSI6ImRlZmF1bHQiLCJpYXQiOjE1NjA4NTc0MjA3NjUsImV4cCI6MTU2NjA0MTQyMDc2NSwiZGV2aWNlSWQiOiI1Y2RkMzQ1MjI0ODMyYmZmNmQzN2Q1NjQiLCJidXNpbmVzc0lkIjoiNWNmYTU2ZWQyZjI5ZjcwMjA4MzQwZDI4Iiwib3V0bGV0SWQiOiI1Y2NkOGI2NjkxYzM1MDY5N2I1OWQ3YzEifQ.hMkgg6f-88vpikKd_aaalWtO0D_nPVHDmTy0BOhVaXo';

  public itemListObj: any;
  public ItemObj: any = {
    Price: [{}, {}, {}]
  };
  ItemOBJ:IItem;

  private schema = {

    type: 'object',
    properties: {
       hello: { type: 'number',
               minimum:5,
               maximum:25 } },

    additionalProperties: false,
    required: ['hello']

  };

  //importeditem={} as IItem;
  public page = 1;
  public page_il = 1;
  public limit = 15;
  public limit_il = 100;
  public Items: any;
  public businessId: any;
  public SelectedItemList: number;
  public SelectedILName: any ='';
  public isPost: Boolean = true;
  public itemsList: any;
  public isTable: Boolean = false;
  public isCreate: Boolean = false;
  public showItemList: Boolean = false;
  public itemToDelete: any ={};
  public isItemListEdit: Boolean = false;
  public itemList: any = '';
  public itemListName: any = '';
  public selectedRow:any= '';
  public IName: any = '';
  public itemListSelected = false;
  public queryResponseArrived = true;
  csvContent: string;
  importedList = [] as IItem[];

  constructor(
    private itemsService: ItemsService,
    private itemListService: ItemListService,
    private toasterService: ToastrService,
    private settingService: SettingsService,
    private devicesService: DevicesService,
  ) {}

  ngOnInit() {
    this.businessId = this.settingService.getBusinessId();
    this.itemListSelected = false;
    this.queryResponseArrived = false;
    // this.terminalToken = localStorage.getItem('terminaltoken');
    this.getTerminalToken();
    console.log('TT ',this.terminalToken)
     const ajv = new Ajv({ allErrors: true });
     const test = ajv.compile(ItemDSchema);
     const obj = { hello1: 3 }
     const isValid = test(obj);
     console.log(isValid ? obj : { obj, error: test.errors })
  }
  getTerminalToken(){
    const body = {
      token : localStorage.getItem('token'),
      businessId : localStorage.getItem('businessId')
    }
    this.devicesService.getTerminalToken(body)
    .subscribe( resp => {
      this.terminalToken = resp.token
      console.log('terminal token ', resp )
      this.getItemList();
    })
  }
  public onkeydown = function(e) {
    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58) 
      || e.keyCode == 8)) {
        if ((e.keyCode != 190) && (e.keyCode != 110)) {
          if (e.keyCode === 9) {
            e.stopPropagation();
            return true;
          } else {
            return false;
          }
        }
    }
  }
  public onSearchClick(){
    console.log(this.IName)
    this.getItems(this.SelectedItemList)
  }
  public getItemList() {
    this.itemListService.get(this.businessId,this.page_il,this.limit_il)
      .subscribe(resp => {
        console.log(resp.docs)
        this.itemListObj = resp.docs
        if (this.itemListObj.length > 0) {
          this.itemListSelected = true;
          this.SelectedItemList = this.itemListObj[0]._id;
          this.SelectedILName = this.itemListObj[0].itemListName
          this.getItems(this.SelectedItemList);
        }
      }, error => {
        console.log('Not created Successfully')
      })
  }
  public createItemList(itemList) {
    itemList.businessId = this.settingService.getBusinessId();
    if(this.isItemListEdit){
      itemList.itemListId = this.SelectedItemList;
      itemList.itemListName = this.itemListName;
      this.itemListService.patch(itemList)
      .subscribe(resp => {
        this.modalCreateItemList.hide();
        this.toasterService.success('ItemList Details Modified successfully');
        this.getItemList();
      }, error => {
        console.log(error);
      })
    } else {
      itemList.itemListName = this.itemListName
      this.itemListService.create(itemList)
      .subscribe(resp => {
        this.modalCreateItemList.hide();
        this.toasterService.success('ItemList Created successfully');
        this.SelectedItemList = itemList._id;
        this.getItemList();
      }, error => {
        if (error.error.message != undefined) {
          this.toasterService.error(error.error.message);
        }
        console.log("error------------------>", error)
      })
    }
  }
  public createItemListModel() {
    this.itemListName = ""
    this.isItemListEdit = false
    this.modalCreateItemList.show();
    setTimeout(() => {
      this.modalItemListFocusInput.nativeElement.focus();
    }, 500);
  }

  public deleteItem(){
    this.itemsService.delete(this.itemToDelete, this.terminalToken)
    .subscribe(resp => {
      this.toasterService.success('Item Deleted');
      this.modalDeleteItem.hide()
      this.getItems(this.SelectedItemList);
    }, error => {
      this.toasterService.error(error.error.message);
    })
  }

  public deleteAllItems(){
    this.itemsService.deleteAllItems(this.SelectedItemList, this.terminalToken)
    .subscribe(resp => {
      this.toasterService.success('All Items Deleted Successfully');
      this.modalDeleteAllItems.hide();
      this.page = 1;
      this.getItems(this.SelectedItemList);
    }, error => {
      if ((error.error != undefined) && (error.error != null)) {
        if ((error.error.message != undefined) && (error.error.message != null)) {
          this.toasterService.error(error.error.message);
        }
      }
    })
  }

  public allItemListClick(){
    this.showItemList = true;
  }

  public createItem(ItemObj) {
    let obj = JSON.parse(JSON.stringify(ItemObj));
    obj.Price = obj.Price.filter(value => Object.keys(value).length !== 0);
    if (this.isPost) {
      this.itemsService.post(obj, this.SelectedItemList, this.terminalToken)
        .subscribe(resp => {
          this.modalCreateItem.hide()
          this.toasterService.success('Create Item successful');
          if (this.SelectedItemList != null) {
            this.getItems(this.SelectedItemList);
          }
        })
    } else {
      let items: any = [{}];
      delete obj._id;
      delete obj.deleteStatus;
      delete obj.ItemListId;
      delete obj.Timestamp;
      for (let i = 0; i < obj.Price.length; i++) {
        delete obj.Price[i]._id;
      }
      items[0] = obj;
      let isEnforce = false;
      this.itemsService.put(items, this.SelectedItemList, this.terminalToken,isEnforce)
        .subscribe(resp => {
          this.modalCreateItem.hide()
          this.toasterService.success('Updated Item details successful');
          if (this.SelectedItemList != null) {
            this.getItems(this.SelectedItemList);
          }
        })
    }
  }
  public createItemModel(ItemList) {
    if(ItemList != null)
    {
      this.SelectedItemList = ItemList._id;
      this.SelectedILName = ItemList.itemListName
    }
    this.ItemObj = {
      Price: [{}, {}, {}],
      ICode: 0
    };
    this.isCreate = true;
    this.modalCreateItem.show();
    setTimeout(() => {
      this.modalCreateItemFocusInput.nativeElement.focus();
    }, 500);
    this.isPost = true;
    this.itemsService.getItemCodeForList(this.SelectedItemList, this.terminalToken)
    .subscribe(resp => {
      for (var i = 0; i < resp.length; i++) {
        if ((i + 1) != resp[i].ICode) {
          break;
        }
      }
      this.ItemObj.ICode = (i + 1);
    }, err => {
      console.log(err);
      if (err.error.message.includes('Items not found') === true) {
        this.ItemObj.ICode = 1;
      }
    })
  }

  public getItems(id) {
    console.log(id, this.IName, this.page, this.limit);
    this.itemsService.getItems(id, this.IName, this.terminalToken, this.page, this.limit)
    .subscribe(resp => {
      console.log(resp)
      this.Items = resp.docs
      this.itemsList = resp
      if (this.Items !== undefined) {
        for (let j = 0; j < this.Items.length; j++) {
          for (let i = 0; i < 3; i++) {
            if (this.Items[j].Price[i] === undefined) {
              this.Items[j].Price[i] = {};
            }
          }
        }
        this.queryResponseArrived = true;
      }
    }, error => {
      console.log(error);
      this.queryResponseArrived = true;
    })
  }
  public onItemListChange() {
    console.log('onItemListChange')
    this.page = 1; this.limit = 15;
    this.getItems(this.SelectedItemList);
  }
  public onItemListClick(ItemList,i){
    this.page = 1;
    this.IName = "";
    this.SelectedItemList = ItemList._id
    this.SelectedILName = ItemList.itemListName
    this.getItems(ItemList._id);
    this.selectedRow=i;
    console.log("selected=",i)
  }
  public deleteItemList(itemListName) {
    this.itemListService.patch(this.itemList)
    .subscribe(resp => {
      this.modalDeleteItemList.hide();
      this.toasterService.success('ItemList deleted successfully');
      this.getItemList();
    }, error => {
      console.log('error======================>', error);
    })
  }
  public onItemEditClick(Item) {
    this.isCreate = false;
    this.modalCreateItem.show();
    setTimeout(() => {
      this.modalCreateItemFocusInput.nativeElement.focus();
    }, 500);
    this.ItemObj = JSON.parse(JSON.stringify(Item));
    this.isPost = false;
  }
  public onItemDeleteClick(item) {
    console.log('delete item ',item)
    this.itemToDelete = item;
    this.modalDeleteItem.show();
    setTimeout(() => {
      this.modalDeleteItemFocusInput.nativeElement.focus();
    }, 500);
  }
  
  public onDeleteAllItemsClick() {
    this.modalDeleteAllItems.show()
    setTimeout(() => {
    this.modalDeleteAllItemsFocusInput.nativeElement.focus();
    }, 500);
  }

  public pageChanged(e) {
    this.queryResponseArrived = false;
    this.page = e.page;
    this.getItems(this.SelectedItemList);
  }
  public onTableViewClick(){
    if(this.isTable){
      this.isTable = false
    }
    else{
      this.isTable = true
    }
  }
  public onBackClick(){
    this.showItemList = false
  }
  public onItemListEditClick(itemListName) {
    this.isItemListEdit = true;
    this.itemListName = "";
    this.modalCreateItemList.show();
    setTimeout(() => {
      this.modalItemListFocusInput.nativeElement.focus();
    }, 500);
  }

  public onItemListDeleteClick(itemListName) {
    this.modalDeleteItemList.show();
  }

  public click ()
  {
    debugger;
    console.log(this.importedList)
  }
  public onFileLoad(fileLoadedEvent) {
    const textFromFileLoaded = fileLoadedEvent.target.result;
    this.csvContent = textFromFileLoaded;
    var array = this.csvContent.split(/\r?\n/);
    var that = this;
    this.importedList = [];
    const arraySource = from(array);
    arraySource.subscribe(val =>{
      var array =  val.split(/,/);
      var importeditem = {} as IItem;

      importeditem.ICode = Number(array[1]);
      importeditem.IName = array[2]
      if (array[0].length >= 2) {
        importeditem.HSN = array[0]
      } else {
        delete importeditem.HSN;
      }
      if ((array[3] !== undefined) && (array[3] !== null) && (array[3].length >= 1)) {
        importeditem.Unit = array[3]
      } else {
        delete importeditem.Unit;
      }
      importeditem.Price = [];
      importeditem.Price[0] = Object.create({});
      console.log(Number(array[4]));
      if (isNaN(Number(array[4])) !== true) {
        importeditem.Price[0].Price = Number(array[4])
      }
      if (isNaN(Number(array[5])) !== true) {
        importeditem.Price[0].CGST = Number(array[5])
      }
      if (isNaN(Number(array[6])) !== true) {
        importeditem.Price[0].SGST = Number(array[6])
      }
      if (isNaN(Number(array[7])) !== true) {
        importeditem.Price[0].IGST = Number(array[7])
      }
      importeditem.Price[1] = Object.create({});
      if (isNaN(Number(array[8])) !== true) {
        importeditem.Price[1].Price = Number(array[8])
      }
      if (isNaN(Number(array[9])) !== true) {
        importeditem.Price[1].CGST = Number(array[9])
      }
      if (isNaN(Number(array[10])) !== true) {
        importeditem.Price[1].SGST = Number(array[10])
      }
      if (isNaN(Number(array[11])) !== true) {
        importeditem.Price[1].IGST = Number(array[11])
      }

      importeditem.Price[2] = Object.create({});
      if (isNaN(Number(array[12])) !== true) {
        importeditem.Price[2].Price = Number(array[12])
      }
      if (isNaN(Number(array[13])) !== true) {
        importeditem.Price[2].CGST = Number(array[13])
      }
      if (isNaN(Number(array[14])) !== true) {
        importeditem.Price[2].SGST = Number(array[14])
      }
      if (isNaN(Number(array[15])) !== true) {
        importeditem.Price[2].IGST = Number(array[15])
      }
      
      if (isNaN(Number(array[16])) !== true) {
        importeditem.CP = Number(array[16])
      }
      if (isNaN(Number(array[17])) !== true) {
        importeditem.GCode = Number(array[17])
      }
      importeditem.GName = array[18]
      if (isNaN(Number(array[19])) !== true) {
        importeditem.DCode = Number(array[19])
      }
      importeditem.DName = array[20]

      importeditem.OpenPriceFlag = Boolean(array[21])
      importeditem.DecimalDisableFlag = Boolean(array[22])
      if (isNaN(Number(array[23])) !== true) {
        importeditem.DiscPercent = Number(array[23])
      }
      if (isNaN(Number(array[24])) !== true) {
        importeditem.CESS1 = Number(array[24])
      }
      if (isNaN(Number(array[25])) !== true) {
        importeditem.CESS2 = Number(array[25])
      }
      that.importedList.push(importeditem)
    }, null, () => {
      this.fileInputVariable.nativeElement.value = "";//clear Item file selected
      that.Items = that.importedList;
      that.Items.splice(-1, 1);
      if (Number.isNaN(that.Items[0].ICode) === true) {
        that.Items.splice(0, 1);
      }
      let putCalls = [];
      let isEnforce = true;
      for (var i = 0; i < ((that.Items.length / 100) | 0x00); i++) {
        let itemsSend = [];
        itemsSend = that.Items.slice((i * 100), ((i + 1) * 100));
        putCalls.push(this.itemsService.put(itemsSend, this.SelectedItemList, this.terminalToken, isEnforce));
      }
      if(that.Items.length % 100 != 0) {
        let itemsSend = [];
        itemsSend = that.Items.slice((that.Items.length - (that.Items.length % 100)), that.Items.length);
        putCalls.push(this.itemsService.put(itemsSend, this.SelectedItemList, this.terminalToken, isEnforce));
      }
      forkJoin(...putCalls).subscribe(result => {
        console.log('result: ', result);
        this.getItems(this.SelectedItemList);
        this.toasterService.success('Items successfully uploaded to server');
      }, error => {
        console.log('error: ', error);
        this.toasterService.error('Error in sending file data to server');
        this.getItems(this.SelectedItemList);
      })
    });
  }

  onFileSelect(input: HTMLInputElement) {
    const files = input.files;
    if (files && files.length) {
      const fileToRead = files[0];
      const fileReader = new FileReader();
      fileReader.onload = this.onFileLoad.bind(this);
      fileReader.readAsText(fileToRead, "UTF-8");
    }
  }

  public errorHandling(error) {
    if ((error === undefined) || (error === null)) {

    } else if ((error.error === undefined) || (error.error === null)) {

    }
  }
}
